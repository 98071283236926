import React from "react";
import { ArrowDown } from "../assets/icons";
import { Link, animateScroll as scroll } from "react-scroll";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Banner = () => {
  const {
    section: {
      frontmatter: {
        white_box_text,
        phone,
        email,
        image,
        emailImage,
        phoneImage,
      },
    },
  } = useStaticQuery(graphql`
    query Banner {
      section: markdownRemark(fileAbsolutePath: { regex: "/banner/" }) {
        frontmatter {
          white_box_text
          phone: phone_number
          email
          image: main_banner_image {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          emailImage: banner_email_image {
            publicURL
          }
          phoneImage: banner_phone_image {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <div className="max-w-full h-mobile-banner relative">
      <div className="w-full">
        <Img fluid={image.childImageSharp.fluid} alt="main-museum" />
      </div>
      <div className="flex flex-col justify-center md:justify-start bg-white p-5 py-0 md:py-5 my-5 md:my-0 lg:absolute md:mx-auto lg:mx-0  md:w-64 right-0 bottom-0 md:bottom-auto banner-main ">
        <h1 className="text-right text-4xl md:text-5xl font-medium text-black  uppercase leading-snug Baloo">
          {white_box_text}
        </h1>
        <div className="flex justify-between items-center mt-4">
          <div className="flex flex-col text-black text-sm font-bold">
            <div className="py-1 flex">
              <img
                className="mr-2"
                alt="Phone icon"
                src={phoneImage.publicURL}
              />

              <a href={`tel:${phone}`}>
                {" "}
                <span>{phone}</span>
              </a>
            </div>
            <div className="py-1 flex">
              <img
                className="mr-2"
                alt="Email icon"
                src={emailImage.publicURL}
              />

              <a href={`mailto:${email}`}>
                {" "}
                <span> {email}</span>
              </a>
            </div>
          </div>
          <Link
            className="p-4 bg-green cursor-pointer"
            to="contact"
            spy={true}
            smooth={true}
            offset={-120}
            duration={900}
            hashSpy={true}
          >
            <ArrowDown />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
