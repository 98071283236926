import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const About = () => {
  const {
    section: {
      frontmatter: { description, images },
    },
  } = useStaticQuery(graphql`
    query About {
      section: markdownRemark(fileAbsolutePath: { regex: "/about/" }) {
        frontmatter {
          description: about_text
          images: about_batches_images {
            publicURL
          }
        }
      }
    }
  `);
  return (
    <div className="max-w-6xl mx-auto py-4 md:py-8 px-5" id="about">
      <div className="w-full  md:py-12  flex items-center flex-wrap">
        <div className="w-full md:w-2/5 text-lg flex about-images flex-wrap justify-center">
          {images?.map((item) => {
            return <img src={item.publicURL} className="mx-2" />;
          })}
        </div>
        <div className="w-full md:w-3/5 my-4 md:my-0 text-2xl md:p-12 text-justify  Baloo font-medium mb-4 md:mb-0">
          {description}
        </div>
      </div>
    </div>
  );
};

export default About;
