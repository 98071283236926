import React, { useState, useEffect } from "react";
import FsLightbox from "fslightbox-react";
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash";
import Swiper from "react-id-swiper";
import BackgroundImage from "gatsby-background-image";
import { ParallaxBanner } from "react-scroll-parallax";
import Img from "gatsby-image";
import { Parallax, Background } from "react-parallax";
export const Gallery = () => {
  const {
    Gallery: {
      frontmatter: { gallery_images, gallery_cover_image },
    },
  } = useStaticQuery(graphql`
    query Gallery {
      Gallery: markdownRemark(fileAbsolutePath: { regex: "/gallery/" }) {
        frontmatter {
          gallery_images {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          gallery_cover_image {
            childImageSharp {
              fluid(maxWidth: 2500, maxHeight: 1400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const OnlyImages = gallery_images.map((image) => {
    return image.childImageSharp.fluid.src;
  });
  const [viewOpen, setViewOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [currentTab, setTab] = useState(0);
  const splitedImages = _.chunk(OnlyImages, 12);

  const openLightbox = (event) => {
    setCurrentImage(event);
    setViewOpen(!viewOpen);
  };
  // Gallery Image rendering
  const _imageBlockRenderer = (img, index) => {
    return (
      <div className="w-full">
        <img
          key={index}
          onClick={() => openLightbox(index)}
          className="px-2 md:pr-4 pb-4 w-full md:w-1/2 mx-auto  cursor-pointer"
          src={img}
          alt="Gallery Image"
          style={{ maxHeight: "400px" }}
        ></img>
      </div>
    );
  };
  const [swiper, setSwiper] = useState(null);
  const [currentIndex, setcurrentIndex] = useState(0);
  const handleSlideChange = (index) => {
    if (swiper) {
      swiper.slideTo(index + 1);
      setcurrentIndex(index);
    }
  };
  const params = {
    slidesPerView: 1,
    loop: true,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", function () {
        setcurrentIndex(swiper?.activeIndex - 1);
      });
    }
  }, [swiper]);
  let x = `calc(50vw - 50px - ${currentIndex * 145}px)`;
  console.log(x);
  return (
    <React.Fragment>
      <Parallax
        bgImage={gallery_cover_image?.childImageSharp?.fluid?.src}
        strength={500}
        className="w-full gallery-cover my-24 px-5 md:px-0"
      >
        <div style={{ height: "100%" }} />
      </Parallax>
      <div className="w-full overflow-hidden px-5">
        <div className="max-w-6xl mx-auto md:py-8" id="gallery">
          <h2 className="uppercase text-black font-medium text-5xl Baloo">
            Gallery
          </h2>
          <div className="w-full mx-auto mt-16">
            <Swiper {...params} getSwiper={setSwiper}>
              {OnlyImages.map((img, index) => {
                return _imageBlockRenderer(img, index);
              })}
            </Swiper>
          </div>

          <div>
            <FsLightbox
              toggler={viewOpen}
              sources={splitedImages[currentTab]}
              slide={currentImage + 1}
            />
          </div>
        </div>
        <div
          className="flex items-center overflow-visible w-full gallery-thumbnails px-2 mb-12"
          style={{
            transform: `translateX(${x})`,
          }}
        >
          {gallery_images?.map((img, index) => {
            return (
              <div
                className={`gallery-thumbnail ${
                  index === currentIndex && "active"
                }`}
                onClick={() => {
                  handleSlideChange(index);
                }}
              >
                <Img
                  className="w-full"
                  style={{ height: "100%" }}
                  fluid={img?.childImageSharp?.fluid}
                />
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};
