import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { CONTACT } from "../helpers/api";
import { makePostRequest } from "../helpers/requests";
import { Formik } from "formik";
import Img from "gatsby-image";
const Contact = () => {
  const {
    Serving: {
      frontmatter: { serving },
    },
    Contact: {
      frontmatter: { phone, email, emailImage, phoneImage, mapImage },
    },
    Merlin: { websiteId },
  } = useStaticQuery(graphql`
    query Footer {
      Serving: markdownRemark(fileAbsolutePath: { regex: "/about/" }) {
        frontmatter {
          serving: footer_serving
        }
      }
      Contact: markdownRemark(fileAbsolutePath: { regex: "/banner/" }) {
        frontmatter {
          phone: phone_number
          email
          image: main_banner_image {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          emailImage: banner_email_image {
            publicURL
          }
          phoneImage: banner_phone_image {
            publicURL
          }
          mapImage: banner_map_image {
            publicURL
          }
        }
      }
      Merlin: merlinWebsite {
        websiteId
      }
    }
  `);

  return (
    <div className="px-5 md:py-6 max-w-6xl mx-auto " id="contact">
      <h2 className="uppercase text-black font-medium text-5xl Baloo">
        Contact
      </h2>
      <div className="flex flex-wrap lg:flex-no-wrap max-w-5xl mx-auto  my-10">
        <div className="w-full lg:w-1/3 bg-grey h-full lg:mx-10 mb-5 lg:mb-0  flex flex-col justify-between p-5 pb-10">
          <div className="flex w-full mb-5 lg:my-0">
            <img
              className="px-2 border-2 mr-3 border-green bg-white"
              src={mapImage.publicURL}
              style={{ height: "40px", width: "40px" }}
              alt="Map Marker"
            ></img>
            <div className="flex flex-col  font-medium Baloo">
              <h3 className="text-xl">SERVING:</h3>
              <div className="text-md">{serving}</div>
            </div>
          </div>
          <div className="flex w-full mb-5 lg:my-0">
            <img
              className="px-2 border-2 mr-3 border-green bg-white"
              src={emailImage.publicURL}
              style={{ height: "40px", width: "40px" }}
              alt="Email Icon"
            ></img>
            <div className="flex flex-col  font-medium Baloo">
              <h3 className="text-xl">EMAIL:</h3>
              <a href={`mailto:${email}`}>
                <div className="text-md">{email}</div>
              </a>
            </div>
          </div>
          <div className="flex w-full mb-5 lg:my-0">
            <img
              className="px-2 border-2 mr-3 border-green bg-white"
              src={phoneImage.publicURL}
              style={{ height: "40px", width: "40px" }}
              alt="Phone Icon"
            ></img>
            <div className="flex flex-col  font-medium Baloo">
              <h3 className="text-xl">PHONE:</h3>
              <a href={`tel:${phone}`}>
                <div className="text-md">{phone}</div>
              </a>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3 bg-grey h-full">
          <Formik
            initialValues={{
              email: "",
              name: "",
              message: "",
              subject: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.name) {
                errors.name = "Name is Required";
              }
              if (!values.message) {
                errors.message = "Message is Required";
              }

              return errors;
            }}
            onSubmit={async (values) => {
              let { email, name, message, subject } = values;

              try {
                const { data: emailAddress } = await makePostRequest(
                  CONTACT,
                  {
                    email,
                    name,
                    message,
                  },
                  { websiteId }
                );
              } catch (error) {}
            }}
          >
            {({
              errors,
              touched,
              handleChange,

              handleSubmit,

              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit} className="p-5 Baloo">
                <div className="w-full flex flex-wrap md:flex-no-wrap mb-4">
                  <input
                    name="name"
                    className="p-3 placeholder-black italic mb-5 md:mb-0 w-full md:w-1/3"
                    placeholder="Your Name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  ></input>
                  <input
                    name="email"
                    className="p-3  placeholder-black italic  md:ml-5 w-full md:w-2/3"
                    placeholder="Your Email"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  ></input>
                </div>

                <input
                  name="subject"
                  className="w-full p-3 placeholder-black italic mb-4"
                  placeholder="Subject"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                ></input>

                <textarea
                  name="message"
                  className="w-full p-3 placeholder-black italic mb-3"
                  placeholder="Message"
                  style={{ height: "9rem" }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                ></textarea>

                <div
                  className={`w-full flex ${
                    Object.entries(errors).length == 0
                      ? "justify-end"
                      : "justify-between"
                  }`}
                >
                  {Object.entries(errors).length != 0 && (
                    <ul>
                      {Object.values(errors).map((value) => {
                        return <li className="mb-1 text-red-500">{value}</li>;
                      })}
                    </ul>
                  )}
                  <button
                    style={{ height: "3rem" }}
                    className="bg-green text-white py-3 px-16"
                    type="submit"
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Contact;
