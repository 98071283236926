import React, { useState } from "react";
import Review from "../components/Review";
import Swiper from "react-id-swiper";
import { ParallaxBanner } from "react-scroll-parallax";
import { Parallax, Background } from "react-parallax";
import { graphql, useStaticQuery } from "gatsby";

const Reviews = () => {
  const {
    section: {
      frontmatter: { image },
    },
    reviews: { edges: reviews },
  } = useStaticQuery(graphql`
    query {
      section: markdownRemark(
        fileAbsolutePath: { regex: "/data/reviews.md/" }
      ) {
        frontmatter {
          image: reviews_image {
            childImageSharp {
              fluid(maxWidth: 2500, maxHeight: 1400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      reviews: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/data/allreviews/" } }
      ) {
        edges {
          node {
            frontmatter {
              writer: review_writer

              comment: review_comment
              place: review_place
              rating: review_rating
            }
          }
        }
      }
    }
  `);
  const [swiper, setSwiper] = useState(null);
  const params = {
    slidesPerView: 2.5,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.05,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1.15,
        spaceBetween: 30,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 1.5,
        spaceBetween: 30,
      },
      1020: {
        slidesPerView: 2.5,
        spaceBetween: 30,
      },
    },
  };

  return (
    <React.Fragment>
      <div className="bg-grey py-12 overflow-hidden">
        <div className=" max-w-6xl mx-auto md:py-8 mt-8 px-5 " id="reviews">
          <h2 className="font-medium text-5xl Baloo uppercase">Testimonials</h2>
          <div className="w-full overflow-visible testimonials-main mt-12">
            <Swiper {...params} getSwiper={setSwiper}>
              {reviews.map(({ node: { frontmatter: data } }, index) => {
                return (
                  <div
                    key={index}
                    className="w-full bg-white review-card mb-24"
                  >
                    <Review data={data} />
                  </div>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
      {/* <div className="w-full gallery-cover my-24 px-5 md:px-0">
        <BackgroundImage
          fluid={image?.childImageSharp?.fluid}
          style={{ height: "100%" }}
        ></BackgroundImage>
      </div> */}
      {/* <ParallaxBanner
        className="w-full gallery-cover my-24 px-5 md:px-0"
        layers={[
          {
            image: image?.childImageSharp?.fluid?.src,
            amount: 0.5,
          },
        ]}
        style={{ height: "600px" }}
      ></ParallaxBanner> */}

      <Parallax
        bgImage={image?.childImageSharp?.fluid?.src}
        strength={500}
        className="w-full gallery-cover my-24 px-5 md:px-0"
      >
        <div style={{ height: "100%" }} />
      </Parallax>
    </React.Fragment>
  );
};

export default Reviews;
