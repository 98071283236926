import Axios from "axios";

export const makePostRequest = async (url, data, params = {}, config = {}) => {
  try {
    params = !!params ? params : {};

    return await Axios.post(url, data, {
      params,
      timeout: 15000,
    });
  } catch (error) {
    throw error;
  }
};

export const makeGetRequest = async (url, params = {}, config = {}) => {
  try {
    return await Axios.get(url, {
      params: { ...params },
      timeout: 15000,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};
