const API_ROUTE = getApiServer();
export const SITE = `${API_ROUTE}/client/siteData`;
export const CONTACT = `${API_ROUTE}/client/contact`;
const merlinAPIEndpoint = (endpoint = "") => {
  return `${API_ROUTE}/${endpoint}`;
};

export const MERLIN_INSTAGRAM = merlinAPIEndpoint(
  process.env.INSTAGRAM_ACCESS_TOKEN
    ? `?access_token=${process.env.INSTAGRAM_ACCESS_TOKEN}`
    : ""
);

function getApiServer() {
  return "https://api.merlinpanel.com/v1";
}
