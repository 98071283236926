import React, { useEffect, useState } from "react";
import { MERLIN_INSTAGRAM } from "../helpers/api";
import { makeGetRequest } from "../helpers/requests";
import Img from "gatsby-image";
const InstagramFeed = () => {
  const [instagramPosts, setInstagramPosts] = useState(Array(4).fill());
  useEffect(() => {
    async function fetchInstagramPosts() {
      try {
        console.log(MERLIN_INSTAGRAM);
        const instagramPostsData = await makeGetRequest(MERLIN_INSTAGRAM);
        console.log(instagramPostsData);
        setInstagramPosts(
          instagramPostsData
            .slice(0, 4)
            .map(({ id, image, time, link, caption }) => ({
              id,
              image,
              date: new Date(time * 1000),
              link,
              description: caption,
            }))
        );
      } catch (error) {
        // handleError(error);
      }
    }

    fetchInstagramPosts();
  }, []);
  console.log(instagramPosts);
  return (
    <div className="py-12 md:py-24 ">
      <div className="max-w-6xl mx-auto px-5">
        <h2 className="uppercase text-black font-medium text-5xl Baloo">
          Instagram Feed
        </h2>
        <div className="flex flex-wrap insta-feed justify-between mt-12 md:mt-24">
          {instagramPosts?.map((post = {}, index) => {
            return (
              <div className="instagram-feed-card" key={post?.id ?? index}>
                <div
                  className="background-image"
                  style={{ background: `#f5f5f5, url(${post?.image})` }}
                ></div>

                <h3 className="uppercase text-grey font-medium text-2xl Baloo my-4">
                  {post?.date ?? "1 january 2020"}
                </h3>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InstagramFeed;
