import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
const Team = () => {
  const {
    Team: {
      frontmatter: { description, owner_description, image },
    },
  } = useStaticQuery(graphql`
    query Team {
      Team: markdownRemark(fileAbsolutePath: { regex: "/team/" }) {
        frontmatter {
          description: team_main_description
          owner_description: team_owner_description
          image: team_group_photo {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div className="max-w-6xl mx-auto mt-8 md:py-8 px-5" id="team">
      <h2 className="uppercase text-black font-medium text-5xl Baloo">Team</h2>
      <div className="text-center py-8 px-2 md:px-12 lg:px-24">
        <h2 className=" text-black font-medium text-xl Baloo ">
          {description}
        </h2>
      </div>
      <div className="my-5 w-full">
        <Img fluid={image.childImageSharp.fluid} alt="Team Group Photo"></Img>
      </div>
      <div className="text-center py-8 px-2 md:px-12 lg:px-24">
        <h2 className=" text-black font-medium text-xl Baloo ">
          {owner_description}
        </h2>
      </div>
    </div>
  );
};

export default Team;
