import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
export const Review = ({ data }) => {
  const { writer, comment, place, rating } = data;
  return (
    <div className="flex flex-col p-12 h-full Baloo justify-between">
      <div className="w-full">
        <h2 className="text-2xl font-bold ">{writer}</h2>
        <div className="text-left text-xl font-medium">{place}</div>
        <div className="text-left text-lg font-medium mt-8">{comment}</div>
      </div>
      <span className="mt-8">
        {Array.from({ length: parseInt(rating) }, (i) => {
          return <FontAwesomeIcon icon={faStar} color="#FFD000" />;
        })}
      </span>
    </div>
  );
};
export default Review;
