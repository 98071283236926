import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../sections/Banner";
import About from "../sections/About";
import Services from "./services";
import { Gallery } from "../sections/Gallery";
import Reviews from "../sections/Reviews";
import Team from "../sections/Team";
import Contact from "../sections/Contact";
import InstagramFeed from "../sections/instagram-feed";

function IndexPage({ data }) {
  const ServicesData = data.Services.edges;

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <section className="w-full">
        <Banner />
        <About />
        <Services data={ServicesData} />
        <InstagramFeed />
        <Gallery />
        <Reviews />
        <Team />
        <Contact />
      </section>
    </Layout>
  );
}
export const query = graphql`
  query {
    Services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___id], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            service_title
            service_description
            service_images {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default IndexPage;
